import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";

const titleStart = "OMOCEANS ";
const DEFAULT_TITLE = titleStart;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { title: titleStart + " AcroYoga Gathering" },
  },
  {
    path: "/branding",
    name: "Branding",
    meta: { title: titleStart + " branding" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/Branding.vue"),
  },
  {
    path: "/know-us",
    name: "KnowUs",
    meta: { title: titleStart + " Lua & Pedro" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/KnowUsView.vue"),
  },
  {
    path: "/retreat-list",
    name: "RetreatList",
    meta: { title: titleStart + " Group retreats" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/RetreatListView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to: any) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  document.title = to.meta.title || DEFAULT_TITLE;
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
